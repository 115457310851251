<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        <span class="primary--text-kh">{{$t('caption.initialInfo')}}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="8">
                                <v-btn class="ma-2" outlined @click="$router.push('/staff/add')">{{$t('button.newStaff')}}</v-btn>

                                <v-btn class="ma-2" outlined>Export</v-btn>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field :prepend-inner-icon="icons.mdiMagnify" rounded single-line outlined dense v-model="search"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table :headers="headers" :items="staffList" item-key="id" class="table-rounded" disable-sort :search="search">
                            <template #[`item.name`]="{item}">
                                <div class="d-flex flex-column">
                                    <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
                                </div>
                            </template>

                            <template #[`item.gender`]="{item}">
                                <div class="d-flex flex-column">
                                    <span v-if="item.gender === 1">ស្រី</span>
                                    <span v-else>ប្រុស</span>
                                </div>
                            </template>
                            <template #[`item.dob`]="{item}">
                                <span v-if="item.dob">{{formatDate(item.dob)}}</span>
                            </template>
                            <template #[`item.action`]="{item}">
                                <v-btn class="btn" small rounded outlined @click="viewTraining(item)">
                                    {{$t('button.viewMore')}}
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row id="focusMe">
            <v-col cols="12" v-if="selected">
                <v-card>
                    <v-card-title class="ml-4 printIcon">
                        <span class="primary--text" v-text="$t('caption.personalInfo')" />
                        <v-spacer></v-spacer>
                        <v-icon medium class="mr-2 printIcon" @click="editItem(selectedStaff)">{{icons.mdiPencil}}</v-icon>
                        <v-icon medium class="mx-5 printIcon" @click="print('focusMe')">{{icons.mdiPrinter}}</v-icon>
                    </v-card-title>
                    <div class="print-header">{{$t('caption.personalInfo')}}</div>
                    <v-card-text class="ml-4">
                        <v-row>
                            <v-col v-if="url" cols="12" sm="3">
                                <img v-if="url" :src="url" width="200px" />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-alert outlined color="black">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                {{$t('text.name')}}:
                                                <b>{{selectedStaff.nameKh}}</b>
                                            </v-col>
                                            <v-col cols="12">
                                                {{$t('text.dob')}}:
                                                <span v-if="selectedStaff.dob">
                                                    <b>{{formatDate(selectedStaff.dob)}}</b>
                                                </span>
                                            </v-col>
                                            <v-col cols="12">
                                                {{$t('text.placeOfBirth')}}:
                                                <b>{{selectedStaff.pob}}</b>
                                            </v-col>
                                            <v-col cols="12" v-if="selectedStaff.maritalStatus">
                                                {{$t('text.maritalStatus')}}:
                                                <span v-if="$t('lang') == 'KH'">
                                                    <b>{{getMartialStatusById(selectedStaff.maritalStatus)[0].nameKh}}</b>
                                                </span>
                                                <span v-else>
                                                    <b>{{getMartialStatusById(selectedStaff.maritalStatus)[0].nameEn}}</b>
                                                </span>
                                            </v-col>
                                            <v-col cols="12" v-else>
                                                {{$t('text.maritalStatus')}}:
                                                <b>{{$t('text.informationNotAvailable')}}</b>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-alert>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-alert outlined color="black">
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12">
                                                {{$t('text.nationalCard')}}:
                                                <b>{{selectedStaff.nationalID}}</b>
                                            </v-col>
                                            <v-col cols="12">
                                                {{$t('text.phoneNumber')}}:
                                                <b>{{selectedStaff.phone}}</b>
                                            </v-col>
                                            <v-col cols="12">
                                                {{$t('text.currentAddress')}}:
                                                <b>{{selectedStaff.address}}</b>
                                            </v-col>
                                            <v-col cols="12" v-if="selectedStaff.facebook">
                                                Facebook:
                                                <b>{{selectedStaff.facebook}}</b>
                                            </v-col>
                                            <v-col cols="12" v-if="selectedStaff.tiktok">
                                                TikTok:
                                                <b>{{selectedStaff.tiktok}}</b>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-alert>
                            </v-col>
                        </v-row>

                    </v-card-text>
                    <v-expansion-panels multiple v-model="expandedPanels">
                        <!-- Relatives -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-card-title class="printIcon">
                                    <span class="primary--text">{{$t('caption.relatives')}}</span>
                                </v-card-title>
                            </v-expansion-panel-header>
                            <div class="print-header">{{$t('caption.relatives')}}</div>
                            <v-expansion-panel-content>
                                <v-card-text>
                                    <div v-if="relativesList.length > 0">
                                        <div v-for="item in relativesList" :key="item.id">
                                            <v-row>
                                                <v-col cols="12" sm="3">
                                                    {{$t('text.name')}}:
                                                    <b>{{item.nameKh}}</b>
                                                </v-col>
                                                <v-col cols="12" sm="2" v-if="(item.relation.nameEn != 'Father' && item.relation.nameEn != 'Mother' && item.relation.nameEn != 'Spouse')">
                                                    {{$t('text.gender')}}:
                                                    <b v-if="item.gender === 1">{{$t('text.female')}}</b>
                                                    <b v-else-if="item.gender === 0">{{$t('text.male')}}</b>
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    {{$t('text.dob')}}:
                                                    <span v-if="item.dob">
                                                        <b>{{formatDate(item.dob)}}</b>
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="3">
                                                    {{$t('text.relation')}}:
                                                    <b>{{item.relation.nameKh}}</b>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="my-2"></v-divider>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p>{{$t('text.informationNotAvailable')}}</p>
                                    </div>

                                </v-card-text>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- End relatives -->
                        <!-- Working Background -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-card-title>
                                    <span class="primary--text">{{$t('caption.workBackground')}}</span>
                                </v-card-title>
                            </v-expansion-panel-header>
                            <div class="print-header">{{$t('caption.workBackground')}}</div>
                            <v-expansion-panel-content>
                                <v-card-text>
                                    <div v-if="workList.length > 0">
                                        <div v-for="item in workList" :key="item.id">
                                            <v-row>
                                                <v-col cols="12" sm="4">
                                                    <b>{{item.officeName}}</b>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    {{$t('text.address')}}:
                                                    <b>{{item.officeAddress}}</b>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    {{$t('text.country')}}:
                                                    <b>{{item.country}}</b>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" sm="4">
                                                    {{$t('text.from')}}
                                                    <strong> {{formatDate(item.startDate)}} </strong> {{$t('text.to')}}
                                                    <strong>{{formatDate(item.endDate)}} </strong>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    {{$t('text.position')}}:
                                                    <b>{{item.position}}</b>
                                                </v-col>
                                                <v-col cols="12" sm="4">
                                                    {{$t('text.appointmentNo')}}:
                                                    <b>{{item.appointmentNo}}</b>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="my-2"></v-divider>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p>{{$t('text.informationNotAvailable')}}</p>
                                    </div>
                                </v-card-text>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- End Working background -->
                        <!-- Education -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-card-title>
                                    <span class="primary--text">{{$t('caption.education')}}</span>
                                </v-card-title>
                            </v-expansion-panel-header>
                            <div class="print-header">{{$t('caption.education')}}</div>
                            <v-expansion-panel-content>
                                <v-card-text>
                                    <div v-if="educationList.length > 0">
                                        <v-simple-table cellpadding="5">
                                            <thead>
                                                <tr>
                                                    <th>{{$t('text.universityName')}}</th>
                                                    <th>{{$t('text.degreeTitle')}}</th>
                                                    <th>{{$t('text.country')}}</th>
                                                    <th>{{$t('text.startDateToEndDate')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in educationList" :key="item.id">
                                                    <td>{{item.institutionName}}</td>
                                                    <td>{{item.qualificationTitle}}</td>
                                                    <td>{{item.country}}</td>
                                                    <td>{{formatDate(item.startDate)}} - {{formatDate(item.endDate)}}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </div>
                                    <div v-else>
                                        <p>{{$t('text.informationNotAvailable')}}</p>
                                    </div>
                                </v-card-text>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- End Education backround -->
                        <!-- Training Background -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-card-title>
                                    <span class="primary--text">{{$t('caption.training')}}</span>
                                </v-card-title>
                            </v-expansion-panel-header>
                            <div class="print-header">{{$t('caption.training')}}</div>
                            <v-expansion-panel-content>
                                <v-card-title>ជំនាញវិជ្ជាជីវៈ</v-card-title>
                                <v-card-text>
                                    <div v-if="trainingList.length > 0">
                                        <v-simple-table cellpadding="5">
                                            <thead>
                                                <tr>
                                                    <th>វគ្គ ឬកម្រិតសិក្សា</th>
                                                    <th>គ្រឹស្ថានសិក្សា</th>
                                                    <th>សញ្ញាបត្រទទួលបាន</th>
                                                    <th>រាជធានី/ខេត្ត</th>
                                                    <th>ប្រទេស</th>
                                                    <th>ថ្ងៃខែឆ្នាំសិក្សានិងបញ្ចប់</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in trainingList" :key="item.id">
                                                    <td>{{item.title}}</td>
                                                    <td>{{item.venue}}</td>
                                                    <td>{{item.trainingType.nameKh}}</td>
                                                    <td>{{item.trainingCity}}</td>
                                                    <td>{{item.trainingCountry}}</td>
                                                    <td>{{formatDate(item.startDate)}}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                        <!--
                                <div v-for="item in trainingList" :key="item.id">
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-icon light left>{{icons.mdiClock}}</v-icon><strong> {{formatDate(item.startDate)}} </strong> {{$t('text.to')}} <strong>{{formatDate(item.endDate)}} </strong>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            {{item.title}}
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            {{item.venue}}
                                        </v-col>
                                        
                                    </v-row>
                                    <v-divider class="my-2"></v-divider>
                                </div>
                                -->
                                    </div>
                                    <div v-else>
                                        <p>{{$t('text.informationNotAvailable')}}</p>
                                    </div>

                                </v-card-text>
                                <v-card-title>ភាសាបរទេស</v-card-title>
                                <v-card-text>
                                    <div v-if="languageSkillList.length > 0">
                                        <v-simple-table cellpadding="5">
                                            <thead>
                                                <tr>
                                                    <th>ភាសា</th>
                                                    <th>ការអាន</th>
                                                    <th>ការសរសេរ</th>
                                                    <th>ការនិយាយ</th>
                                                    <th>ការស្តាប់</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in languageSkillList" :key="item.id">
                                                    <td v-if="$t('lang') == 'KH'">
                                                        {{getlanguageById(item.skillId)[0].skillNameKh}}
                                                    </td>
                                                    <td v-else>
                                                        {{getlanguageById(item.skillId)[0].skillNameEn}}
                                                    </td>

                                                    <td>{{item.reading}}</td>
                                                    <td>{{item.writing}}</td>
                                                    <td>{{item.speaking}}</td>
                                                    <td>{{item.listening}}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </div>
                                    <div v-else>
                                        <p>{{$t('text.informationNotAvailable')}}</p>
                                    </div>

                                </v-card-text>
                                <v-card-title>ជំនាញកុំព្យូទ័រ</v-card-title>
                                <v-card-text>

                                    <div v-if="computerSkillList.length > 0">
                                        <v-simple-table cellpadding="5">
                                            <thead>
                                                <tr>
                                                    <th>ជំនាញ</th>
                                                    <th>និទ្ទេស</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in computerSkillList" :key="item.id">
                                                    <td v-if="$t('lang') == 'KH'">
                                                        {{getlanguageById(item.skillId)[0].skillNameKh}}
                                                    </td>
                                                    <td v-else>
                                                        {{getlanguageById(item.skillId)[0].skillNameEn}}
                                                    </td>

                                                    <td>{{item.grade}}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </div>
                                    <div v-else>
                                        <p>{{$t('text.informationNotAvailable')}}</p>
                                    </div>
                                </v-card-text>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- End training backround -->
                        <!-- Politic Service -->
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-card-title>
                                    <span class="primary--text">{{$t('caption.politicalService')}}</span>
                                </v-card-title>
                            </v-expansion-panel-header>
                            <div class="print-header">{{$t('caption.politicalService')}}</div>
                            <v-expansion-panel-content>
                                <v-card-text>
                                    <div v-if="politicList.length > 0">
                                        <v-simple-table>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">ឈ្មោះបក្ស ឬអង្គការចាត់តាំង</th>
                                                    <th>ទីកន្លែង</th>
                                                    <th>តួនាទី</th>
                                                    <th>លេខកាត ឬលិខិតតែងតាំង</th>
                                                    <th>ថ្ងៃខែចាប់ផ្តើម និងបញ្ចប់</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in politicList" :key="item.id">
                                                    <td>{{item.partyName}}</td>
                                                    <td>{{item.address}}</td>
                                                    <td>{{item.position}}</td>
                                                    <td>{{item.cardNumber}}</td>
                                                    <td>{{formatDate(item.startDate)}} - {{formatDate(item.endDate)}}</td>
                                                </tr>
                                            </tbody>

                                        </v-simple-table>
                                    </div>
                                    <div v-else>
                                        <p>{{$t('text.informationNotAvailable')}}</p>
                                    </div>

                                </v-card-text>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <!-- End Politic Service -->
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import {
  mdiPencil,
  mdiDelete,
  mdiMagnify,
  mdiFileEdit,
  mdiPrinter,
  mdiClock,
  mdiOpenInNew,
  mdiInformation
} from '@mdi/js'
import StaffService from '@/services/service.staff'
import StaffDetailService from '@/services/service.staffDetail'
import SystemService from '@/services/service.system'
import moment from 'moment'

export default {
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiMagnify,
        mdiFileEdit,
        mdiPrinter,
        mdiClock,
        mdiOpenInNew,
        mdiInformation
      },
      maritalStatusList: [],
      staffList: [],
      search: '',
      panel: [],
      trainingList: [],
      educationList: [],
      workList: [],
      relativesList: [],
      politicList: [],
      languageSkillList: [],
      computerSkillList: [],
      languageList: [],
      selected: false,
      selectedStaff: {},
      expandedPanels: [],
      url: '/images/covers/jpg.png'
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('text.staffNameKh'), value: 'nameKh' },
        { text: this.$t('text.staffNameEn'), value: 'nameEn' },
        { text: this.$t('text.gender'), value: 'gender' },
        { text: 'DOB', value: 'dob' },
        { text: this.$t('text.position'), value: 'position.nameKh' },
        { text: this.$t('text.department'), value: 'department.nameKh' },
        { text: 'More action', value: 'action' }
      ]
    }
  },
  created() {
    StaffService.getAll().then(response => {
      this.staffList = response.data
    })
    SystemService.getMaritalStatusAll().then(response => {
      this.maritalStatusList = response.data
    })

    SystemService.getSkillAll().then(response => {
      this.languageList = response.data
    })
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView(true)
    },
    formatDate(date) {
      //  alert(JSON.stringify(date));
      if (date) return moment(date).format('DD-MM-YYYY')
      else {
        return ''
      }
    },
    editItem(item) {
      this.$router.push({
        name: 'add-staff',
        params: { action: 'edit', data: item }
      })
    },
    deleteItem(item) {
      alert(JSON.stringify(item))
    },
    getMartialStatusById(id) {
      return this.maritalStatusList.filter(function(elem) {
        if (elem.id === id) return elem
      })
    },
    getlanguageById(id) {
      return this.languageList.filter(function(elem) {
        if (elem.id === id) return elem
      })
    },

    async viewTraining(item) {
      await StaffDetailService.getTrainingByStaffId(item.id).then(response => {
        this.trainingList = response.data
      })

      await StaffDetailService.getWorkByStaffId(item.id).then(response => {
        this.workList = response.data
      })

      await StaffDetailService.getEducationByStaffId(item.id).then(response => {
        this.educationList = response.data
      })

      await StaffDetailService.getRelativesByStaffId(item.id).then(response => {
        this.relativesList = response.data
      })

      await StaffDetailService.getPoliticalServiceByStaffId(item.id).then(
        response => {
          this.politicList = response.data
        }
      )

      await StaffDetailService.getLanguageSkillByStaffId(item.id).then(
        response => {
          this.languageSkillList = response.data
        }
      )

      await StaffDetailService.getComputerSkillByStaffId(item.id).then(
        response => {
          this.computerSkillList = response.data
        }
      )

      if (item.profilePicture) {
        this.getPhotoByStaffId(item.id)
      } else {
        this.url = '/images/covers/jpg.png'
      }
      this.selected = true
      this.selectedStaff = item
      this.scroll('focusMe')
    },
    async getPhotoByStaffId(id) {
      await StaffService.getPictureByStaffId(id).then(response => {
        this.url = URL.createObjectURL(response.data)
      })
    },
    print(id) {
      this.expandedPanels = [0, 1, 2, 3, 4]
      const options = {
        name: '_blank',
        specs: ['fullscreen=no', 'titlebar=no', 'scrollbars=no'],
        styles: ['bootstrap.css', 'kidlat.css']
      }

      this.$nextTick(() => {
        setTimeout(() => {
          this.$htmlToPaper(id, options)
          // window.print()
        }, 500)
      })

      //  this.$htmlToPaper(id, options)
    }
  }
}
</script>
<style>
.print-header {
  display: none;
}

@media print {
  .v-expansion-panel-header {
    display: none !important;
  }

  .print-header {
    display: block;
    font-size: 16px;
    font-weight: bold;
  }

  .v-expansion-panel {
    border: none;
    box-shadow: none;
  }

  .v-expansion-panel-header > * {
    display: none !important;
  }
}
</style>

